<template>
  <div>
    <!-- 工程专利-数据统计页面 -->
    <Header title="数据统计" index="首页" titleOne="工程专利" beforeTitle="数据统计" />
    <div class="content">
      <div>
        <h2>案卷数据</h2>
        <el-card class="box-card">
          <div class="card-box">
            <div class="flex-l">
              <img
                style="width: 64px;margin: 0 16px 0 12px;"
                src="../../assets/images/patent_total_icon.png"
                alt
              />
              <div>
                <span>案卷总数</span>
                <h1>{{ patent_count }}</h1>
              </div>
            </div>
            <div class="flex-r">
              <div>
                <span>今日登记专利数</span>
                <h1>{{ patent_count_today }}</h1>
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <div>
        <h2>专利任务</h2>
        <el-card class="box-card">
          <div class="card-box">
            <div class="flex-l">
              <img
                style="width: 64px;margin: 0 16px 0 12px;"
                src="../../assets/images/patent_abnormal_icon.png"
                alt
              />
              <div>
                <span>专利任务</span>
                <h1 @click="patentMission()" class="text-dec">{{ mission_count_wait_all }}</h1>
              </div>
            </div>
            <div class="flex-r">
              <div class="r-r">
                <span>待办任务</span>
                <h1 @click="patentMission()" class="text-dec">{{ mission_count_wait }}</h1>
              </div>
              <!-- <div class="r-r">
                <span>7天异常</span>
                <h1 class="text-red text-dec">12</h1>
              </div>-->
            </div>
          </div>
        </el-card>
      </div>
      <div>
        <h2>专利通知书</h2>
        <el-card class="box-card">
          <div class="card-box">
            <div class="flex-l">
              <img
                style="width: 64px;margin: 0 16px 0 12px;"
                src="../../assets/images/patent_notice_icon.png"
                alt
              />
              <div>
                <span>7天内未答复通知书</span>
                <h1 @click="patentMiddle(days = '7')" class="text-dec">{{ middle_count }}</h1>
              </div>
            </div>
            <div class="flex-r">
              <div class="r-r">
                <span>15天内未答复通知书</span>
                <h1 @click="patentMiddle(days = '15')" class="text-dec">{{ middle_count_fifteen }}</h1>
              </div>
              <div class="r-r">
                <span>30天内未答复通知书</span>
                <h1 @click="patentMiddle(days = '30')" class="text-dec">{{ middle_count_thirty }}</h1>
              </div>
              <div class="r-r">
                <span>过期未答复</span>
                <h1
                  @click="patentMiddle(days = '-1')"
                  class="text-red text-dec"
                >{{ middle_count_expire }}</h1>
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <div>
        <h2>专利费用</h2>
        <el-card class="box-card">
          <div class="card-box">
            <div class="flex-l">
              <img
                style="width: 64px;margin: 0 16px 0 12px;"
                src="../../assets/images/patent_fee_icon.png"
                alt
              />
              <div>
                <span>7天内到期费用</span>
                <h1 @click="patentFee(days = '7')" class="text-dec">{{ fee_count }}</h1>
              </div>
            </div>
            <div class="flex-r">
              <div class="r-r">
                <span>15天内到期费用</span>
                <h1 @click="patentFee(days = '15')" class="text-dec">{{ fee_count_fifteen }}</h1>
              </div>
              <div class="r-r">
                <span>30天内到期费用</span>
                <h1 @click="patentFee(days = '30')" class="text-dec">{{ fee_count_thirty }}</h1>
              </div>
              <div class="r-r">
                <span>过期费用</span>
                <h1 @click="patentFee(days = '-1')" class="text-red text-dec">{{ fee_count_expire }}</h1>
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <span style="margin-top: 30px;display: block;">注：数字带有下划线的可点击操作！</span>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      patent_count: '',//案卷数据
      patent_count_today: '',//案卷数据-今日
      export_count: '',
      middle_count: '',//专利通知书-7
      middle_count_fifteen: '',//专利通知书-15
      middle_count_thirty: '',//专利通知书-30
      middle_count_expire: '',//专利通知书-过期
      fee_count: '',//专利费用-7
      fee_count_fifteen: '',//专利费用-15
      fee_count_thirty: '',//专利费用-30
      fee_count_expire: '',//专利费用-过期
      mission_count: '',//专利任务
      mission_count_wait: '',//专利任务-待办-3
      mission_count_wait_all: ''//专利任务待办-all
    }
  },
  created () {
    this.getPatentCount()
    this.getMiddleCount()
    this.getfeeCount()
    this.getMissionCount()
  },
  methods: {
    patentMiddle (days) {
      this.$router.push({ path: '/patent/middle', query: { days: days } })
    },
    patentFee (days) {
      this.$router.push({ path: '/patent/fee', query: { days: days } })
    },
    // 专利任务跳转
    patentMission () {
      this.$router.push('/patent/mission')
    },
    // 案卷数据
    getPatentCount () {
      this.axios.get('/api/project_patent/get_patent_count', { params: { type: 'all' } }).then((res) => {
        this.patent_count = res.data.count
        this.axios.get('/api/project_patent/get_patent_count', { params: { type: 'today' } }).then((res) => {
          this.patent_count_today = res.data.count
        })
      })
    },
    // 专利任务
    getMissionCount () {
      this.axios.get('/api/project_patent/get_mission_count', { params: { days: '0' } }).then((a) => {
        this.mission_count_wait_all = a.data.count
        this.axios.get('/api/project_patent/get_mission_count', { params: { days: '3' } }).then((a) => {
          this.mission_count_wait = a.data.count
        })
      })
    },
    // 专利通知书
    getMiddleCount () {
      this.axios.get('/api/project_patent/get_middle_count', { params: { days: '7' } }).then((a) => {
        this.middle_count = a.data.count
        this.axios.get('/api/project_patent/get_middle_count', { params: { days: '15' } }).then((a) => {
          this.middle_count_fifteen = a.data.count
          this, axios.get('/api/project_patent/get_middle_count', { params: { days: '30' } }).then((a) => {
            this.middle_count_thirty = a.data.count
            this.axios.get('/api/project_patent/get_middle_count', { params: { days: '-1' } }).then((a) => {
              this.middle_count_expire = a.data.count
            })
          })
        })
      })
    },
    //专利费用
    getfeeCount () {
      this.axios.get('/api/project_patent/get_fee_count', { params: { days: '7' } }).then((a) => {
        this.fee_count = a.data.count
        this.axios.get('/api/project_patent/get_fee_count', { params: { days: '15' } }).then((a) => {
          this.fee_count_fifteen = a.data.count
          this, axios.get('/api/project_patent/get_fee_count', { params: { days: '30' } }).then((a) => {
            this.fee_count_thirty = a.data.count
            this.axios.get('/api/project_patent/get_fee_count', { params: { days: '-1' } }).then((a) => {
              this.fee_count_expire = a.data.count
            })
          })
        })
      })
    },
  },
}
</script>

<style scoped>
div/deep/.el-card__body {
  padding: 32px 20px;
}
.card-box {
  display: flex;
  align-items: center;
}
.flex-l {
  width: 270px;
  display: flex;
  align-items: center;
  border-right: 1px solid #d8dce6;
  /* padding-right: 100px; */
  margin-right: 135px;
}
.flex-l h1 {
  font-size: 31px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  margin: 0;
}
.flex-l span {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
}
.flex-r {
  display: flex;
  align-items: center;
}
.flex-r h1 {
  font-size: 31px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  margin: 0;
}
.flex-r span {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
}
.r-r {
  width: 270px;
  margin-right: 100px;
}
.text-dec {
  width: 40px;
  text-decoration: underline;
  cursor: pointer;
}
.text-red {
  color: #f50d0d;
}
</style>